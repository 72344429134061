import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet'
import '../styles/index.css'

export default function Live({ data }) {
    let isFinshed = false;

    return (
        <div className="container">
            <Helmet>
                <title>SOLLYY | UPCOMING SHOWS</title>
                <link rel="icon" href="/favicon.ico" />
            </Helmet>

            <main>
                <h1 className='title'>TAP IN</h1>
                <h2 className='subtitle'>upcoming live shows</h2>
                {data.allContentfulLiveShows.nodes.map(node => (
                    <p style={{ textDecorationLine: node.finishDateAndTime > 1 ? 'line-through' : 'none', textDecorationStyle: 'solid', maxWidth: '320px' }}><h3 className='links link-header' ><a href={node.link} target="_blank">{node.title}{node.title2 && <span><br />{node.title2}</span>}</a></h3>
                        {node.timeAndPlace}</p>)
                )}
                <br />
                <p><a href="/">go back</a><br /></p>
            </main>
        </div>
    )
}

export const query = graphql`
query LiveShowQuery {
    allContentfulLiveShows(sort: {fields: finishDateAndTime}) {
      nodes {
        title
        title2
        link
        timeAndPlace
        finishDateAndTime(difference: "minutes")
      }
    }
  }
`